import React from "react";
import PrivacyStyles from "./styles";
import pageInject from "../inject";
import ArticleWrapper from "../../wrappers/Article";

const B = ({ children }) => <strong>{children}</strong>;

const PrivacyPage = ({ classes }) => {
  return (
    <div className={classes.page}>
      <ArticleWrapper>
        <div className={classes.content}>
          <h1>Privacy Policy</h1>
          <h2>Last updated July 09, 2023</h2>
          <p>
            Thank you for choosing to be part of our community at
            Pandemonium2020 (&quot;
            <B>Company</B>&quot;, &quot;<B>we</B>&quot;, &quot;<B>us</B>
            &quot;, &quot;
            <B>our</B>&quot;). We are committed to protecting your personal
            information and your right to privacy. If you have any questions or
            concerns about this privacy notice, or our practices with regards to
            your personal information, please contact us at
            dev@macuyler.com.
          </p>
          <p>
            When you use our mobile application, as the case may be (the &quot;
            <B>App</B>
            &quot;) and more generally, use any of our services (the &quot;
            <B>Services</B>
            &quot;, which include the App), we appreciate that you are trusting
            us with your personal information. We take your privacy very
            seriously. In this privacy notice, we seek to explain to you in the
            clearest way possible what information we collect, how we use it and
            what rights you have in relation to it. We hope you take some time
            to read through it carefully, as it is important. If there are any
            terms in this privacy notice that you do not agree with, please
            discontinue use of our Services immediately.
          </p>
          <p>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our App), as well as,
            any related services, sales, marketing or events.
          </p>
          <p>
            <B>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </B>
          </p>
          <h3>TABLE OF CONTENTS</h3>
          <ol>
            <li>
              <a href="#one">WHAT INFORMATION DO WE COLLECT?</a>
            </li>

            <li>
              <a href="#two">HOW DO WE USE YOUR INFORMATION?</a>
            </li>

            <li>
              <a href="#three">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
            </li>

            <li>
              <a href="#four">
                IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </a>
            </li>

            <li>
              <a href="#five">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>

            <li>
              <a href="#six">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </li>

            <li>
              <a href="#seven">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>

            <li>
              <a href="#eight">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>

            <li>
              <a href="#nine">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>

            <li>
              <a href="#ten">DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </li>

            <li>
              <a href="#eleven">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </li>

            <li>
              <a href="#twelve">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
          </ol>
          <div id="one">
            <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
            <h5>Information automatically collected</h5>
            <p>
              <B>In Short:</B> Some information — such as your Internet Protocol
              (IP) address and/or browser and device characteristics — is
              collected automatically when you visit our App.
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the App. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our App and other technical
              information. This information is primarily needed to maintain the
              security and operation of our App, and for our internal analytics
              and reporting purposes.
            </p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage and performance information our servers
                automatically collect when you access or use our App and which
                we record in log files. Depending on how you interact with us,
                this log data may include your IP address, device information,
                browser type and settings and information about your activity in
                the App (such as the date/time stamps associated with your
                usage, pages and files viewed, searches and other actions you
                take such as which features you use), device event information
                (such as system activity, error reports (sometimes called
                &apos;crash dumps&apos;) and hardware settings).
              </li>
            </ul>
          </div>
          <div id="two">
            <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
            <p>
              <B>In Short:</B> We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </p>
            <p>
              We use personal information collected via our App for a variety of
              business purposes described below. We process your personal
              information for these purposes in reliance on our legitimate
              business interests, in order to enter into or perform a contract
              with you, with your consent, and/or for compliance with our legal
              obligations. We indicate the specific processing grounds we rely
              on next to each purpose listed below.
            </p>
            <p>We use the information we collect or receive:</p>
            <ul>
              <li>
                <B>To send administrative information to you.</B> We may use
                your personal information to send you product, service and new
                feature information and/or information about changes to our
                terms, conditions, and policies.
              </li>
              <li>
                <B>To protect our Services.</B> We may use your information as
                part of our efforts to keep our App safe and secure (for
                example, for fraud monitoring and prevention).
              </li>
              <li>
                <B>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </B>
              </li>
              <li>
                <B>To respond to legal requests and prevent harm.</B> If we
                receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </li>
              <li>
                <B>To send you marketing and promotional communications.</B> We
                and/or our third-party marketing partners may use the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. For example,
                when expressing an interest in obtaining information about us or
                our App, subscribing to marketing or otherwise contacting us, we
                will collect personal information from you. You can opt-out of
                our marketing emails at any time (see the &quot;
                <a href="#seven">WHAT ARE YOUR PRIVACY RIGHTS</a>&quot; below).
              </li>
              <li>
                <B>Deliver targeted advertising to you.</B> We may use your
                information to develop and display personalized content and
                advertising (and work with third parties who do so) tailored to
                your interests and/or location and to measure its effectiveness.
              </li>
            </ul>
          </div>
          <div id="three">
            <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
            <p>
              <B>In Short:</B> We only share information with your consent, to
              comply with laws, to provide you with services, to protect your
              rights, or to fulfill business obligations.
            </p>
            <p>
              We may process or share your data that we hold based on the
              following legal basis:
            </p>
            <ul>
              <li>
                <B>Consent:</B> We may process your data if you have given us
                specific consent to use your personal information for a specific
                purpose.
              </li>
              <li>
                <B>Legitimate Interests:</B> We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </li>
              <li>
                <B>Performance of a Contract:</B> Where we have entered into a
                contract with you, we may process your personal information to
                fulfill the terms of our contract.
              </li>
              <li>
                <B>Legal Obligations:</B> We may disclose your information where
                we are legally required to do so in order to comply with
                applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court
                order or a subpoena (including in response to public authorities
                to meet national security or law enforcement requirements).
              </li>
              <li>
                <B>Vital Interests:</B> We may disclose your information where
                we believe it is necessary to investigate, prevent, or take
                action regarding potential violations of our policies, suspected
                fraud, situations involving potential threats to the safety of
                any person and illegal activities, or as evidence in litigation
                in which we are involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul>
              <li>
                <B>Business Transfers.</B> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
            </ul>
          </div>
          <div id="four">
            <h4>4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4>
            <p>
              <B>In Short:</B> We may transfer, store, and process your
              information in countries other than your own.
            </p>
            <p>
              Our servers are located in. If you are accessing our App from
              outside, please be aware that your information may be transferred
              to, stored, and processed by us in our facilities and by those
              third parties with whom we may share your personal information
              (see &quot;
              <a href="#three">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
              &quot; above), in and other countries.
            </p>
            <p>
              If you are a resident in the European Economic Area, then these
              countries may not necessarily have data protection laws or other
              similar laws as comprehensive as those in your country. We will
              however take all necessary measures to protect your personal
              information in accordance with this privacy notice and applicable
              law.
            </p>
          </div>
          <div id="five">
            <h4>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
            <p>
              <B>In Short:</B> We keep your information for as long as necessary
              to fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than 90 days.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </div>
          <div id="six">
            <h4>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
            <p>
              <B>In Short:</B> We aim to protect your personal information
              through a system of organizational and technical security
              measures.
            </p>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our App is at your own risk. You
              should only access the App within a secure environment.
            </p>
          </div>
          <div id="seven">
            <h4>7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
            <p>
              <B>In Short:</B> You may review, change, or terminate your account
              at any time.
            </p>
            <p>
              If you are a resident in the European Economic Area and you
              believe we are unlawfully processing your personal information,
              you also have the right to complain to your local data protection
              supervisory authority. You can find their contact details{" "}
              <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <p>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available{" "}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
          <div id="eight">
            <h4>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (&quot;DNT&quot;) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As
              such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </p>
          </div>
          <div id="nine">
            <h4>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
            <p>
              <B>In Short:</B> Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the
              &quot;Shine The Light&quot; law, permits our users who are
              California residents to request and obtain from us, once a year
              and free of charge, information about categories of personal
              information (if any) we disclosed to third parties for direct
              marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the App, you have the right to request
              removal of unwanted data that you publicly post on the App. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the App, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
          </div>
          <div id="ten">
            <h4>10. DO WE MAKE UPDATES TO THIS NOTICE? </h4>
            <p>
              <B>In Short:</B> Yes, we will update this notice as necessary to
              stay compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated &quot;Revised&quot; date
              and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy notice, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy notice frequently to be informed of how
              we are protecting your information.
            </p>
          </div>
          <div id="eleven">
            <h4>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
            <p>
              If you have questions or comments about this notice, you may email
              us at dev@macuyler.com or fill out a report on our{" "}
              <a href="/support">support page</a>.
            </p>
          </div>
          <div id="twelve">
            <h4>
              12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h4>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request form by clicking{" "}
              <a href="https://app.termly.io/notify/1531da8f-767c-4a67-ae62-f04ea27316e9">
                here
              </a>
              . We will respond to your request within 30 days.
            </p>
          </div>
          <p className={classes.center}>
            This privacy policy was created using{" "}
            <a
              href="https://termly.io/products/privacy-policy-generator/?ftseo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termly’s Privacy Policy Generator
            </a>
            .
          </p>
        </div>
      </ArticleWrapper>
    </div>
  );
};

export default pageInject(PrivacyStyles)(PrivacyPage);
